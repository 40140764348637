import { useAuthContext } from "context/AuthContext";
import QuestionCard from "elements/QuestionCard";
import { useMemo, useState } from "react";
import client from "utils/client";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import { usePropertyContext } from "../../context";
import { TileSelect } from "elements/TileSelect";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const WILDCARD_QUESTION =
	"If you had a mortgage, would you like to be notified when it's a good time to refinance your home?";
const WILDCARD_QUESTION_TYPE = "[10/09/24] Refinance notifications";

const answerToTinyInt = (
	answer: string | number | boolean | null | undefined
): 1 | 0 | null => {
	if (answer === undefined || answer === null) {
		return null;
	}

	if (!!answer && answer !== "no") {
		return 1;
	}

	return 0;
};

const WildCardQuestion = () => {
	const { send, next, details, activeQuestionId, propertySurveyContext } =
		usePropertyContext();
	const { userData } = useAuthContext();

	const trackWildcardQuestion = useEventTracker();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const completeSurvey = async () => {
		try {
			setError(false);
			setSubmitting(true);

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: "confirmation",
				payload: {},
			});
		} catch {
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const handleSubmit = async (payload?: { value: "yes" | "no" | null }) => {
		if (payload) {
			send({
				type: "update_context",
				context: {
					q_wildcard_type: WILDCARD_QUESTION_TYPE,
					q_wildcard_answer: payload.value,
				},
			});
		}

		try {
			setSubmitting(true);

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					questionType: WILDCARD_QUESTION_TYPE,
					value: payload?.value
						? answerToTinyInt(payload.value)
						: answerToTinyInt(propertySurveyContext?.q_wildcard_answer),
				},
			});

			await completeSurvey();

			trackWildcardQuestion({
				eventName: constants.EVENTS.PORTAL_PROPERTY_SURVEY_WILDCARD,
				data: {
					Question: WILDCARD_QUESTION,
					Answer: propertySurveyContext?.q_wildcard_answer,
					"Users Email": userData?.email,
				},
			});

			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const handleSkip = async () => {
		trackWildcardQuestion({
			eventName: constants.EVENTS.PORTAL_PROPERTY_SURVEY_WILDCARD,
			data: {
				Question: WILDCARD_QUESTION,
				Answer: "Skipped",
				"Users Email": userData?.email,
			},
		});
		await completeSurvey();
		next();
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.q_wildcard_answer === "yes",
				onSelect: () => {
					handleSubmit({ value: "yes" });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.q_wildcard_answer === "no",
				onSelect: () => {
					handleSubmit({ value: "no" });
				},
			},
		],
		[propertySurveyContext.q_wildcard_answer]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="wildcard"
				tooltipText=""
				question={<p className="lg bold">{WILDCARD_QUESTION}</p>}
				withRadio={false}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			<ButtonV2
				size="large"
				disabled={submitting || !propertySurveyContext.q_wildcard_answer}
				type="submit">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default WildCardQuestion;
