import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import {
	generalPropertySurveyQuestionFlowMachine,
	valueIsPropertySurveyQuestionState,
	getQuestionComponent,
	getQuestionId,
} from "./propertySurveyQuestionFlow.machine";
import { useGenericFlowEventTracker } from "context/TrackEventsContext";
import { PropertyDetails } from "utils/types";
import { PropertyModalNames } from "../context";

export const usePropertySurveyFlowMachine = (
	details: PropertyDetails | undefined,
	modalToShow: PropertyModalNames | undefined
) => {
	const generalFlowMachine = useMachine(
		generalPropertySurveyQuestionFlowMachine
	);

	const { trackAnswerEvent, trackViewPageEvent, trackBackEvent, initFlowName } =
		useGenericFlowEventTracker();

	const [state, send, service] = generalFlowMachine;

	useEffect(() => {
		if (modalToShow === "profile") {
			initFlowName("PROPERTY_SURVEY_FLOW");
		}
	}, [modalToShow]);

	const activeQuestionState = React.useMemo(() => {
		if (typeof state.value === "string") {
			return state.value;
		}
		if (
			typeof state.value === "object" &&
			"ADDITIONAL_SERVICES_QUESTIONS" in state.value
		) {
			return state.value.ADDITIONAL_SERVICES_QUESTIONS;
		}
		if (
			typeof state.value === "object" &&
			"QUALIFYING_QUESTIONS" in state.value &&
			typeof state.value.QUALIFYING_QUESTIONS === "object" &&
			"IN_SEASON" in state.value.QUALIFYING_QUESTIONS
		) {
			return state.value.QUALIFYING_QUESTIONS.IN_SEASON;
		}
		if (
			typeof state.value === "object" &&
			"QUALIFYING_QUESTIONS" in state.value &&
			typeof state.value.QUALIFYING_QUESTIONS === "object" &&
			"OUT_OF_SEASON" in state.value.QUALIFYING_QUESTIONS
		) {
			return state.value.QUALIFYING_QUESTIONS.OUT_OF_SEASON;
		}
		return "";
	}, [state]);

	const showBackButton = React.useMemo(
		() => state.nextEvents.includes("back"),
		[state]
	);

	const commonPropetyTrackingData = {
		"Property ID": details?.id,
		Address: details?.address,
		City: details?.city,
	};

	const next = () => {
		const s = send("next");
		trackAnswerEvent({
			stepName: activeQuestionId,
			data: s.context,
			customData: commonPropetyTrackingData,
		});
	};

	const prev = () => {
		trackBackEvent({
			stepName: activeQuestionId,
			customData: commonPropetyTrackingData,
		});
		send("back");
	};

	const init = () => {
		send("init");
	};

	const restart = () => {
		send("restart");
	};

	const Question = React.useMemo(() => {
		if (valueIsPropertySurveyQuestionState(activeQuestionState)) {
			return getQuestionComponent(activeQuestionState);
		}
		// eslint-disable-next-line react/display-name
		return () => <React.Fragment></React.Fragment>;
	}, [activeQuestionState]);

	const activeQuestionId = React.useMemo(() => {
		if (valueIsPropertySurveyQuestionState(activeQuestionState)) {
			return getQuestionId(activeQuestionState);
		}
		return "";
	}, [activeQuestionState]);

	useEffect(() => {
		trackViewPageEvent({
			stepName: activeQuestionId,
			customData: commonPropetyTrackingData,
		});
	}, [activeQuestionId]);

	const isComplete = React.useMemo(
		() => state.context.customer_completed_survey === 1,
		[state.context.customer_completed_survey]
	);

	return {
		state,
		send,
		service,
		init,
		restart,
		next,
		prev,
		isComplete,
		showBackButton,
		activeQuestionId,
		Question,
	};
};
